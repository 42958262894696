import React from 'react'
import { GiOakLeaf, } from 'react-icons/gi'
import {FiScissors} from 'react-icons/fi'
import {FaPencilAlt} from 'react-icons/fa'
import { GiStoneCrafting } from 'react-icons/gi'
import {FaSnowplow} from 'react-icons/fa'
import {RxDotFilled} from 'react-icons/rx'


const About = () => {
  return (
    <div name="Services" className='w-full my-32'>
        <div className='max-w-[1240px] mx-auto'>
            <div className='text-center'>
                <h2 className='text-5xl font-bold'>Trusted by Homeowners in PA</h2>
                <p className='text-2xl py-6 pb-12 text-gray-600'>Farinella's Lawn & Landscape proudly serves the Bensalem, PA region as a fully licensed Landscaping Service.  
                    We take pride in fully satisfying our customers, and treat your home like our own. </p>
            </div>
            {/* box area */}
            <div className='grid lg:grid-cols-5 gap-4 px-2 text-center'>
                <div className='border py-8 rounded-xl shadow-xl hover:scale-110 duration-700 bg-[#476958]'>
                    <FiScissors className='text-green-100 text-5xl mb-6 inline-block' />
                    <p className='text-3xl font-bold text-green-50 mb-6'>Lawn Maintenance</p>
                    <p className='px-8 text-slate-100'>Weekly Lawn cuts with the latest industry standard machinery. </p>
                </div>
                <div className='border py-8 rounded-xl shadow-xl hover:scale-110 duration-700'>
                    <FaPencilAlt className='text-emerald-700 text-5xl mb-6 inline-block' />
                    <p className='text-3xl font-bold text-emerald-700 mb-6'>Landscape Design</p>
                    <p className='px-8'>We offer fully sketched landscape designs, to assist in bringing your landscape ideas to life.</p>
                </div>
                <div className='border py-8 rounded-xl shadow-xl hover:scale-110 duration-700 bg-[#476958]'>
                    <GiStoneCrafting className='text-green-100 text-5xl mb-6 inline-block' />
                    <p className='text-3xl font-bold text-green-50 mb-6'>Hardscaping</p>
                    <p className='px-8 text-green-50'>Our hardscaping services are designed to enhance the beauty and functionality of your outdoor living spaces. 
                        From retaining walls to patios, to walkways, our team of skilled professionals can transform your property into a paradise that you'll love spending time in.
                    </p>
                </div>
                <div className='border py-8 rounded-xl shadow-xl hover:scale-110 duration-700'>
                    <GiOakLeaf className='text-emerald-700 text-5xl mb-6 inline-block' />
                    <p className='text-3xl font-bold text-emerald-700 mb-6'>Spring & Fall Clean-ups</p>
                    <p className='px-8'>With Spring around the corner, we can have your garden beds primed for the upcoming season for fresh makeover</p>
                </div>
                <div className='border py-8 rounded-xl shadow-xl hover:scale-110 duration-700 bg-[#476958]'>
                    <FaSnowplow className='text-green-100 text-5xl mb-6 inline-block' />
                    <p className='text-3xl font-bold text-green-50 mb-6'>Commerical Snow Removal</p>
                    <p className='px-8 text-green-50'>Professional snow removal including plowing, shoveling, salting, and de-icing services. Keep your property clear and safe. Hiring a reliable company ensures a hassle-free and secure business environment.
                    </p>
                </div>
               {/* additional services */}
                
            </div>
            <div name='doink' class='relative mt-10 flex flex-col items-center py-8 mx-2 lg:mx-36
                            m-auto  bg-green-100
                            border border-green-700 rounded-2xl text-center shadow-xl '>
                    <p class='font-bold text-green-900'>Additional Services</p>
                        <div class='grid grid-cols-4 gap-4 mt-4 px-6'>
                            <p class='flex flex-col items-center'>
                                <span class='inline-flex'><RxDotFilled class='text-green-800 mt-1' /><span>Land Leveling</span></span>
                            </p>
                            <p class='flex flex-col items-center'>
                                <span class='inline-flex'><RxDotFilled class='text-green-800 mt-1' /><span>Sodding</span></span>
                            </p>
                            <p class='flex flex-col items-center'>
                                <span class='inline-flex'><RxDotFilled class='text-green-800 mt-1' /><span>Pruning</span></span>
                            </p>
                            <p class='flex flex-col items-center'>
                                <span class='inline-flex'><RxDotFilled class='text-green-800 mt-1' /><span>Hedge Trimming</span></span>
                            </p>
                            <p class='flex flex-col items-center'>
                                <span class='inline-flex'><RxDotFilled class='text-green-800 mt-1' /><span>Rock Installation</span></span>
                            </p>
                            <p class='flex flex-col items-center'>
                                <span class='inline-flex'><RxDotFilled class='text-green-800 mt-1' /><span>Retention Walls</span></span>
                            </p>
                            <p class='flex flex-col items-center'>
                                <span class='inline-flex'><RxDotFilled class='text-green-800 mt-1' /><span>Shrub Removal</span></span>
                            </p>
                            <p class='flex flex-col items-center'>
                                <span class='inline-flex'><RxDotFilled class='text-green-800 mt-1' /><span>Mulching</span></span>
                            </p>
                        </div>
                    </div>
        </div>
    </div>
  )
}

export default About
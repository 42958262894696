import React from 'react'
import { useState } from 'react'

import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs'
import { RxDotFilled } from 'react-icons/rx'
import banner1Resize from '../assets/banner1Resize.jpeg'
import HeroFarinellaResize from '../assets/HeroFarinellaResize.jpeg'
import SideviewResize from '../assets/SideviewResize.jpeg'
import HouselawnResize from '../assets/HouselawnResize.jpeg'
import HardscapeResize from '../assets/HardscapeResize.jpeg'
import FlagRockBedResize from '../assets/FlagRockBedResize.jpeg'

const Gallery = () => {

    const slides = [
        {url: SideviewResize},
        {url: banner1Resize},
        {url: HouselawnResize},
        {url: HardscapeResize},
        {url: FlagRockBedResize},
        {url: HeroFarinellaResize}
      ];
    
      const [currentIndex, setCurrentIndex] = useState(0);
    
      const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };
    
      const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
    
      const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
      };
    
      return (
        <div name='Portfolio' className='max-w-screen h-screen w-full m-auto py-16 px-4 relative group'>
          <div
            style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
            className='w-full h-full rounded-2xl bg-center bg-cover duration-500'
          ></div>
          {/* Left Arrow */}
          <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer hover:bg-black/50'>
            <BsChevronCompactLeft onClick={prevSlide} size={30} />
          </div>
          {/* Right Arrow */}
          <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer hover:bg-black/50'>
            <BsChevronCompactRight onClick={nextSlide} size={30} />
          </div>
          <div className='flex top-4 justify-center py-2'>
            {slides.map((slide, slideIndex) => (
              <div
                key={slideIndex}
                onClick={() => goToSlide(slideIndex)}
                className='text-2xl cursor-pointer'
              >
                <RxDotFilled className='text-black/60 hover:text-black/100' />
              </div>
            ))}
          </div>
        </div>
      );
    }

export default Gallery

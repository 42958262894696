import React from 'react'
import {FaFacebook, FaInstagram} from 'react-icons/fa'
import { Link } from 'react-scroll'


const Footer = () => {
  return (
    <div className='w-full bg-slate-900 text-gray-300 py-y px-2'>

        <div className='max-w-[1240px] mx-auto grid grid-cols-3 md:grid-cols-3 border-b-2 border-gray-600 py-8'>
            <div>
                <h6 className='font-bold uppercase pt-2'>Services</h6>
                <ul className='text-slate-400'>
                    <li className='py-1'>Lawn Maintenance</li>
                    <li className='py-1'>Landscape Design</li>
                    <li className='py-1'>Commerical Snow Removal</li>
                    <li className='py-1'>Spring Cleanups</li>
                    <li className='py-1'>Mulching</li>
                    <li className='py-1'>Sodding</li>
                    <li className='py-1'>Hedge Trimming</li>
                    <li className='py-1'>Rock Installation</li>
                    <li className='py-1'>Shrub Removal</li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2 text-green-750'>Navigate</h6>
                <ul className='text-slate-400'>
                    <li><Link to ="Home" className='py-1 hover:cursor-pointer hover:text-slate-200' smooth={true} duration={500}>Home</Link></li>
                    <li><Link to ="Services" className='py-1 hover:cursor-pointer hover:text-slate-200' smooth={true} offset={-200} duration={500}>Services</Link></li>
                    <li><Link to ="Quote" className='py-1 hover:cursor-pointer hover:text-slate-200' smooth={true} offset={50} duration={500}>Get a Quote!</Link></li>
                    <li><Link to ="Portfolio" className='py-1 hover:cursor-pointer hover:text-slate-200' smooth={true} offset={50} duration={500}>Portfolio</Link></li>
                    <li><Link to ="Testimonials" className='py-1 hover:cursor-pointer hover:text-slate-200' smooth={true} offset={-50} duration={500}>Testimonials</Link></li>
                    <li><Link to ="Contact" className='py-1 hover:cursor-pointer hover:text-slate-200' smooth={true} offset={50} duration={500}>Contact</Link></li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2'>Social</h6>
                <ul className='text-slate-400'>
                    <li className='py-1 hover:text-slate-200'><FaFacebook className='inline-block mr-1 mb-1'/><a href='https://www.facebook.com/Farinellas.Lawn.and.Landscape.LLC/'>Facebook</a></li>
                    <li className='py-1 hover:text-slate-200'><FaInstagram className='inline-block mr-1 mb-1' /><a href='https://www.instagram.com/farinellas.lawn.and.landscape/?hl=en'>Instagram</a></li>
                </ul>
            </div>
        </div>
        <div className='flex max-w-[1240px] px-2 py-4 m-auto justify-between sm:flex-row text-center text-gray-300'>
            <p className='mx-auto text-center'>2023 Farinella's Lawn & Lawnscape, LLC.  All rights reserved</p>
        </div>
    </div>
  )
}

export default Footer
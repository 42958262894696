
import './App.css';
import Services from './components/Services';
// import Hero from './components/Hero';
import Hero2 from './components/Hero2';
import Navbar from './components/Navbar';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import QuoteForm from './components/QuoteForm';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div>
      <Navbar />
      {/* <Hero /> */}
      <Hero2 />
      <Services />
      <Gallery />
      <QuoteForm />
      <Testimonials />
      <Contact />
      <Footer />
      <Toaster position='top-center' />
    </div>
  );
}

export default App;

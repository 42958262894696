import React from 'react'
import TestimonialResize from '../assets/TestimonialResize.jpeg'
import { ArrowSmRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-scroll';


const Testimonials = () => {
  return (
    <div name='Testimonials' className='w-full mt-24'>
      <div className='w-full h-[700px] bg-gray-900/90 absolute'>
        <img className='w-full h-full object-cover mix-blend-overlay' src={TestimonialResize} alt="/" />
      </div>
      
      <div className='max-w-[1240px] mx-auto text-white relative'>
          <div className='px-4 py-12'>
              <h2 className='text-3xl pt-8 text-slate-300 uppercase text-center'>Why us?</h2>
              <h3 className='text-5xl font-bold py-6 text-center'>What our customers say</h3>
              <p className='py-4 text-3xl text-slate-300'></p>
          </div>

          <div className='grid grid-cols-1 lg:grid-cols-3 relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black'>
              <div className='bg-white rounded-xl shadow-2xl overflow-hidden'>
                  <div className='p-8 bg-green-100'>
                      <h3 className='font-bold text-2xl my-6'>Local Bensalem Resident</h3>
                      <p className='text-gray-600 text-xl italic'>"I highly recommend Tyler as a landscaper. He transformed my yard into a beautiful oasis, paying attention to every detail and exceeding my expectations. 
                        Tyler is professional, punctual, and passionate about his work. 
                        He truly cares about his clients and their satisfaction. 
                        I will definitely be using his services again in the future!"</p>
                  </div>
                  <div className='bg-green-50 pl-8 py-4 overflow-hidden'>
                  <Link to ="Quote" className='hover:cursor-pointer'><p className='flex items-center text-gray-600'>Contact Us <ArrowSmRightIcon className='w-5 ml-2' /></p></Link>
                  </div>
              </div>
              <div className='bg-white rounded-xl shadow-2xl overflow-hidden'>
                  <div className='p-8 bg-green-100'>
                      <h3 className='font-bold text-2xl my-6'>Local PA Business Owner</h3>
                      <p className='text-gray-600 text-xl italic'>"A truely excellent experience from beginning to end.  Farinella's Lawn & Landscape gave our business a fair snow removal quote and exuded professionalism.  Our employees and customers can walk with confidence after snowfall with these guys.  Quick and timely, Farinella's Lawn & Landscape is a service to trust your business with."</p>
                  </div>
                  <div className='bg-green-50 pl-8 py-4 overflow-hidden'>
                  <Link to ="Quote" className='hover:cursor-pointer'><p className='flex items-center text-gray-600'>Contact Us <ArrowSmRightIcon className='w-5 ml-2' /></p></Link>
                  </div>
              </div>
              <div className='bg-white rounded-xl shadow-2xl overflow-hidden'>
                  <div className='p-8 bg-green-100'>
                      <h3 className='font-bold text-2xl my-6'>Local Bensalem Resident</h3>
                      <p className='text-gray-600 text-xl italic'>"My go to Landscaping Company. From the initial consultation to the final installation, the team was professional, knowledgeable, and attentive to every detail. The quality of the plants and the landscape design has added significant value to my property and has received numerous compliments from visitors!</p>
                  </div>
                  <div className='bg-green-50 pl-8 py-4 overflow-hidden'>
                  <Link to ="Quote" className='hover:cursor-pointer'><p className='flex items-center text-gray-600'>Contact Us <ArrowSmRightIcon className='w-5 ml-2' /></p></Link>
                  </div>
              </div>
          </div>
      </div>
    </div>
  )
}

export default Testimonials